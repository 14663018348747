// Inspired from
// https://github.com/bluerail/twitter-bootstrap-rails-confirm/blob/v2.0.1/vendor/assets/javascripts/twitter/bootstrap/rails/confirm.js

import { bootstrap, jQuery } from "../old_global_exports";

const defaults = {
  title: null,
  proceed: "OK",
  proceed_class: "btn btn-proceed",
  cancel: "Cancel",
  cancel_class: "btn cancel",
  fade: false,
  modal_class: "",
  autofocus_dismiss: "",
};

const RailsConfirmBootstrapModal = (message: string, $element: JQuery, callback: () => void) => {
  const element = $element[0];

  const $dialog: JQuery = jQuery(
    `
      <div class="modal" id="rails_confirm_bootstrap_modal" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">...</h4>
              <a class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></a>
            </div>
            <div class="modal-body"></div>
            <div class="modal-footer justify-content-between"></div>
            </div>
          </div>
        </div>
    `,
  );

  const dialog = $dialog[0];

  $dialog.addClass(element.getAttribute("data-confirm-modal-class") || defaults.modal_class);

  if (element.getAttribute("data-confirm-fade") || defaults.fade) {
    $dialog.addClass("fade");
  }

  $dialog
    .find(".modal-header .modal-title")
    .html(
      element.getAttribute("data-confirm-title") || defaults.title || window.top.location.origin,
    );

  $dialog.find(".modal-body").html(message.toString().replace(/\n/g, "<br />"));

  const cancelButton = $("<a />", { "href": "#", "data-dismiss": "modal" });
  cancelButton.html(element.getAttribute("data-confirm-cancel") || defaults.cancel);
  cancelButton.addClass(defaults.cancel_class);
  cancelButton.addClass(element.getAttribute("data-confirm-cancel-class") || "btn-secondary");
  cancelButton.on("click", (event) => {
    event.preventDefault();
    return $dialog.modal("hide");
  });
  $dialog.find(".modal-footer").append(cancelButton);

  const confirmButton = $("<a />", { href: "#" });
  confirmButton.html(element.getAttribute("data-confirm-proceed") || defaults.proceed);
  confirmButton.addClass(defaults.proceed_class);
  confirmButton.addClass(element.getAttribute("data-confirm-proceed-class") || "btn-primary");

  if (element.getAttribute("data-autofocus-dismiss") === null || defaults.autofocus_dismiss) {
    setTimeout(() => {
      $(".btn-proceed").focus();
    }, 10);
    confirmButton.attr("autofocus", "autofocus");
  }

  if (element.getAttribute("data-autofocus-dismiss") === "true") {
    setTimeout(() => {
      $(".btn.cancel").focus();
    }, 10);
    cancelButton.attr("autofocus", "autofocus");
  }

  confirmButton.on("click", (e) => {
    e.preventDefault();
    $dialog.modal("hide");
    return callback();
  });

  $dialog.find(".modal-footer").append(confirmButton);

  $dialog.on("keypress", (e) => {
    if (e.which === 13) {
      e.preventDefault();
      $dialog.modal("hide");
      return callback();
    }
  });

  $dialog
    .on("keypress", (e) => {
      if (e.which === 13) {
        $(".modal-footer .btn.btn-proceed").trigger("click");
      }
    })
    .on("hidden hidden.bs.modal", () => {
      $dialog.remove();
    });

  $dialog.appendTo(document.body);
  const modal = new bootstrap.Modal(dialog);
  modal.show();
};

const overwriteRailsAllowAction = (): void => {
  const rails = (jQuery as unknown as { rails: any }).rails as {
    allowAction: ($element: JQuery) => boolean;
    fire: ($element: JQuery, event: string, data?: any) => boolean;
  };

  rails.allowAction = ($element): boolean => {
    const element = $element[0];
    $element.trigger("blur");

    const message = element.getAttribute("data-confirm");
    const answer = false;

    if (!message) {
      return true;
    }

    if (rails.fire($element, "confirm")) {
      RailsConfirmBootstrapModal(message, $element, () => {
        if (rails.fire($element, "confirm:complete", [answer])) {
          const originalAction = rails.allowAction;
          rails.allowAction = (): boolean => {
            return true;
          };

          $element.get(0).click();

          rails.allowAction = originalAction;
          return rails.allowAction;
        }
        return null;
      });
    }
    return false;
  };
};

overwriteRailsAllowAction();
