declare global {
  interface Window {
    jQuery: JQueryStatic;
    I18n: {
      t: (
        key: string,
        opts?: { locale?: string; name?: string; defaultValue?: string; noun?: string },
      ) => string;
    };
  }
}

export const { app }: any = window;

// Avoid using jQuery in new code.
// See https://gitlab.com/syncromsp/wm/monitoringserver/-/merge_requests/12286#discussion_r489479352
// And https://gitlab.com/syncromsp/wm/monitoringserver/-/merge_requests/12286#discussion_r489572040
//
// import jQuery from this file and not
// from `jquery` package to avoid loading jQuery twice in page (which will cause issues).
export const { jQuery } = window;
export const { bootstrap } = window;
export const { I18n } = window;
